import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <Layout>
    <section class='hero is-light is-fullheight'>
      <div class='hero-body'>
        <div class='container'>
          <h1 class='title'>Page non trouvée</h1>
          <h2 class='subtitle'>
            Désolé, la page que vous cherchez n'existe pas.{' '}
          </h2>
          <Link className='button' to='/'>
            Retourner sur la page d'accueil
          </Link>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
